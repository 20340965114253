<template>
  <el-dialog v-model="visible" :title="title" width="640px">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别名称" prop="type">
        <el-input v-model="ruleForm.type"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, reactive, toRefs, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import {
  saveTypeApi
} from './fetch'
export default {
  props: {
    refreshList: Function
  },
  setup (props, ctx) {
    const form = ref(null)
    const route = useRoute()
    const { id } = route.params
    const state = reactive({
      visible: true,
      ruleForm: {
        type: ''
      },
      rules: {
        type: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(() => {

    })

    watch(() => state.visible, (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          ctx.emit('close')
        }, 300)
      }
    })

    const title = computed(() => {
      return id ? '编辑类别' : '新增类别'
    })

    const onSubmit = async () => {
      try {
        console.log(form.value)
        await form.value.validate()
        await saveTypeApi(state.ruleForm)
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
        state.visible = false
        props.refreshList()
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    return {
      ...toRefs(state),
      title,
      form,
      onSubmit
    }
  }
}
</script>
