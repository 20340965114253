import Request from '@/utils/request'

export function getTypeListApi (data) {
  return Request({
    url: '/type',
    params: data
  })
}

export function saveTypeApi (data) {
  return Request({
    url: '/type/save',
    method: 'post',
    data
  })
}
